import { Outlet } from "react-router-dom";
import { AppRouteProps } from "../../components/router/AppRoute";
import Games from ".";

export const gamesRoutes: AppRouteProps[] = [
  {
    path: "games",
    element: <Outlet />,
    children: [{ path: "list", element: <Games /> }],
  },
];
