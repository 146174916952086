import React, { useContext } from "react";
import { mockData } from "../../info/tabs/messages/colDefs";
import { PDataGrid } from "@components/core-components/grid/PDataGrid";
import { ColDef } from "ag-grid-enterprise";
import { TabContext } from "pages/players/details/components/TabContext";

function ListWallets() {
  const { allWallets } = useContext(TabContext);
  const handleDelete = async (params: any) => {
    console.log(params);
  };

  const coldata: ColDef[] = [
    {
      headerName: "Name",
      field: "title",
      autoHeight: true,
      filter: true,
      floatingFilter: true,

      flex: 1,
      cellRenderer: (params: any) => {
        if (params.value) {
          return params.value;
        } else {
          return params?.data?.walletCategory ?? "Wallet";
        }
      },
    },
    {
      headerName: "Category",
      field: "walletCategory",
      autoHeight: true,
      filter: true,
      floatingFilter: true,
      flex: 1,
    },
    {
      headerName: "Currency",
      field: "currency.code",
      autoHeight: true,
      filter: true,
      floatingFilter: true,
      flex: 1,
    },
    {
      headerName: "DELETE",
      width: 108,
      autoHeight: true,
      filter: false,
      floatingFilter: false,
      pinned: "right", // Valid value
      sortable: false,
      cellClass: "",
      cellRenderer: (params: any) => (
        <button
          className="w-20 h-7 bg-danger-light flex flex-row justify-center items-center rounded-md text-danger font-medium text-body-reg-12 hover:bg-danger hover:text-white"
          onClick={() => handleDelete(params)}
        >
          Delete
        </button>
      ),
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  ];
  return (
    <div className="flex flex-col pt-6 pb-9 mb-52 shadow-[0px_2px_6px_rgba(0,0,0,0.1)] bg-white">
      <main className="flex flex-col items-start px-6 w-full text-slate-600 max-md:px-5 max-md:max-w-full">
        <PDataGrid rowData={allWallets} colDefs={coldata} />
      </main>
    </div>
  );
}

export default ListWallets;
