import React from "react";
import cx from "classnames";
import players from "@icons/players.svg";
import usersOcta from "@icons/user-octagon.svg";
import house from "@icons/house.svg";
import ReportIcon from "@icons/report.svg";
import payment from "@icons/payment.svg";
import promotion from "@icons/promotion.svg";
import bookOpen from "@icons/book-open.svg";
import settings from "@icons/settings.svg";
import categoryIcon2 from "@icons/category-2.svg";
import userPen from "@icons/user-pen.svg";
import rightArrow from "@icons/right-arrow.svg";
import leftArrow from "@icons/left-arrow.svg";
import calendar from "@icons/calendar.svg";
import close from "@icons/Popup-Close.svg";
import currency from "@icons/currencytree.svg";
import download from "@icons/download.svg";
import edit from "@icons/edit.svg";
import profile from "@icons/profile.svg";
import verified from "@icons/verified.svg";
import star from "@icons/star.svg";
import checkedstar from "@icons/checked-star.svg";
import filter from "@icons/filter.svg";
import cancel from "@icons/cancel.svg";
import twoChoice from "@icons/two-choice.svg";
import plus from "@icons/plus-sign.svg";
import minus from "@icons/minus-sign.svg";
import search from "@icons/search.svg";
import logo from "@icons/pixup-icon.svg";
import fileupload from "@icons/fileupload.svg";
import purpleArrow from "@icons/purple-arrow.svg";
import whiteArrow from "@icons/white-arrow.svg";
import closeCircle from "@icons/close-circle.svg";
import trash from "@icons/close-circle.svg";
import trashCan from "@icons/trash.svg";
import selected from "@icons/selected.svg";
import downArrow from "@icons/down-arrow.svg";
import games from "@icons/games-icon.svg";
import whiteCancel from "@icons/cancel-white.svg";
import bonus from "@icons/bonus.svg";
import backIcon from "@icons/back-Icon.svg";
import checkbox from "@icons/Checkbox.svg";
import unCheckedbox from "@icons/uncheckbox.svg";
import indigoUpArrow from "@icons/indigo-up-arrow.svg";
import indigoDownArrow from "@icons/indigo-down-arrow.svg";
import move from "@icons/move.svg";
import ellipse10 from "@icons/ellipse10.svg";
import ellipse3 from "@icons/ellipse3.svg";
import UK from "@icons/united-kingdom.svg";
import profileSite from "@icons/profile-site.svg";
import notification from "@icons/notification.svg";
import usdt from "@icons/usdt.svg";
import blueCheckbox from "@icons/BlueCheckbox.svg";
import searchWhite from "@icons/search-white.svg";
import avatarCircle from "@icons/avatar-circle.svg";
import ellipse20 from "@icons/ellipse20.svg";
import carouselRightIcon from "@icons/slider-arrow-right.svg";
import casinoLogo from "@icons/casino-logo.svg";
import facebook from "@icons/facebook.svg";
import whatsapp from "@icons/whatsapp.svg";

type IconProps = {
  iconName: string;
  className?: string;
  svgProps?: React.SVGProps<SVGSVGElement>;
  iconclass?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
};

type IconMap = {
  [key: string]: string;
};

const icons: IconMap = {
  players,
  calendar,
  whiteCancel,
  usersOcta,
  star,
  checkedstar,
  unCheckedbox,
  checkbox,
  games,
  whiteArrow,
  edit,
  purpleArrow,
  indigoUpArrow,
  indigoDownArrow,
  verified,
  cancel,
  filter,
  blueCheckbox,
  download,
  bonus,
  profile,
  house,
  report: ReportIcon,
  close,
  currency,
  plus,
  minus,
  payment,
  promotion,
  bookOpen,
  settings,
  twoChoice,
  categoryIcon2,
  userPen,
  rightArrow,
  leftArrow,
  search,
  logo,
  fileupload,
  closeCircle,
  trash,
  trashCan,
  selected,
  downArrow,
  backIcon,
  move,
  ellipse10,
  ellipse3,
  UK,
  profileSite,
  notification,
  usdt,
  searchWhite,
  avatarCircle,
  ellipse20,
  carouselRightIcon,
  casinoLogo,
  facebook,
  whatsapp,
};

export const Icon: React.FC<IconProps> = ({
  iconName,
  svgProps = { width: 24, height: 24 },
  disabled,
  className,
  ...props
}) => {
  const classNames = cx(className, {
    "pointer-events-none": disabled,
    "cursor-pointer": props?.onClick,
  });

  const SvgIcon = icons[iconName];
  if (!SvgIcon) return null;
  return (
    <div
      className={`flex flex-row justify-center items-center ${classNames} shrink-0`}
      data-testid={iconName}
      {...props}
    >
      <SvgIcon {...svgProps} aria-hidden="true" />
    </div>
  );
};

export default Icon;
