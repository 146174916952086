import { PDataGrid } from "@components/core-components/grid/PDataGrid";
import { useBaseQuery } from "api/core/useBaseQuery";
import { GAMES_API_URLS } from "config/apiUrls";

interface Game {
  category: string;
  game_code: string;
  game_id: string;
  game_name: string;
  provider_name: string;
  status: string;
  sub_provider_name: string;
  url_thumb: string;
  _id: string;
  our_category: [];
}
function Games() {
  const { data: games } = useBaseQuery<Game[]>({
    endpoint: GAMES_API_URLS.GET_GAMES,
  });

  const colDef = [
    {
      headerName: "Category",
      field: "category",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Game Code",
      field: "game_code",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Game ID",
      field: "game_id",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Game Name",
      field: "game_name",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Provider Name",
      field: "provider_name",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Sub Provider Name",
      field: "sub_provider_name",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Thumbnail URL",
      field: "url_thumb",
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => (
        <img
          src={params.value}
          alt="Thumbnail"
          style={{ width: 50, height: 50 }}
        />
      ),
    },
    {
      headerName: "ID",
      field: "_id",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Our Category",
      field: "our_category",
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => (
        <div>
          <div>{params?.value?.name}</div>
        </div>
      ),
    },
  ];
  return (
    <div className="bg-white h-full overflow-auto flex flex-col my-4 p-6">
      <div className="flex-grow h-screen ">
        <PDataGrid showFooter colDefs={colDef} rowData={games || []} />
      </div>
    </div>
  );
}

export default Games;
