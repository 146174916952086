import React, { useContext, useEffect, useState } from "react";
import FinancialCard from "./FinancialCard";
import FinancialBreakdown from "./FinancialBreakdown";
import PIDropDown from "@components/core-components/dropdown";
import { TabContext } from "./TabContext";

const player = {
  balance: "€4.416,00",
  bonusWallet: "€500,00",
  profitability: "8.71%",
  grossGamingRevenue: "-€377,00",
  sportsBookAmount: "€1.740,00",
  casinoAmount: "€690,00",
  exchangeAmount: "€185,00",
};
const FinancialOverview: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState("EUR");
  const [balance, setBalance] = useState<string>("");
  const { allWallets, walletData } = useContext(TabContext);
  const [bonusWallet, setBonusWallet] = useState<any>(null);

  useEffect(() => {
    const bonuswallet = allWallets?.find(
      (wallet: any) => wallet.walletCategory === "Bonus"
    );

    setBonusWallet(bonuswallet ?? null);
    const selectedWallet = walletData?.find(
      (wallet: any) => wallet.currency.code === selectedOption
    );
    if (selectedWallet) {
      setBalance(`${selectedWallet?.currency?.symbol}${selectedWallet?.total}`);
    }
  }, [selectedOption, walletData]);

  const options = walletData?.map((wallet: any) => ({
    value: wallet.currency?.code,
    label: wallet.currency?.code,
  }));
  const financialData = [
    {
      color: "bg-[#45A9EF]",
      amount: balance,
      label: "Balance/EUR, Approximation",
      dropDown: (
        <PIDropDown
          options={options}
          value={selectedOption}
          onChange={(value) => setSelectedOption(value)}
          dropDownlistClassNames="w-[185px] top-[80px] right-[0px]"
          className="h-[25px] w-[25px]"
        />
      ),
    },
    {
      color: "bg-[#009C2C]",
      amount: `${bonusWallet?.currency?.symbol}${bonusWallet?.total}`,
      label: "Bonus Wallet",
    },
    {
      color: "bg-[#FFCF45]",
      amount: player.profitability,
      label: "Player Profitability For Us",
    },
    {
      color: "bg-[#F1416C]",
      amount: player.grossGamingRevenue,
      label: "Gross Gaming Revenue",
    },
  ];

  return (
    <section className="flex flex-col grow shrink min-w-[240px] w-full max-w-[1179px] max-md:max-w-full">
      <div className="flex flex-row gap-3 items-center w-full max-md:max-w-full relative">
        {financialData.map((data, index) => (
          <FinancialCard key={index} {...data} />
        ))}
      </div>
      <FinancialBreakdown
        sportsBookAmount={player.sportsBookAmount}
        casinoAmount={player.casinoAmount}
        exchangeAmount={player.exchangeAmount}
      />
    </section>
  );
};

export default FinancialOverview;
