import React, { useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import BSelect from "@components/core-components/select";
import { useBaseQuery } from "api/core/useBaseQuery";
import { CURRENCY_API_URLS } from "config/apiUrls";
import { mapOptions } from "pages/players/list/components/QuickFilterHeader";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Modal from "@components/core-components/modal";
import { baseService } from "api/core/baseService";
import { useParams } from "react-router-dom";
import { TabContext } from "pages/players/details/components/TabContext";

type AddWalletFormProps = {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  handleOpenModal: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const schema = Yup.object().shape({
  category: Yup.string().required("Category is required"),
  currency: Yup.string().required("Currency is required"),
});

interface IFormInput {
  category: string;
  currency: string;
}

const AddWalletForm: React.FC<AddWalletFormProps> = ({
  isModalOpen,
  handleCloseModal,
}) => {
  const { id } = useParams();
  const { refetch } = useContext(TabContext);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      category: "",
      currency: "",
    },
  });

  const options = [
    { value: "Fiat", label: "Fiat" },
    { value: "Crypto", label: "Crypto" },
    { value: "Bonus", label: "Bonus" },
  ];

  const { data: currency } = useBaseQuery<[]>({
    endpoint: CURRENCY_API_URLS.GET_CURRENCY,
  });

  const currencyOptions = mapOptions(currency, "name");

  const onSubmit = async (values: IFormInput) => {
    try {
      const data = {
        currency: values.currency,
        walletCategory: values.category,
        playerId: id,
      };
      const res = await baseService.add("wallets/add", data);
      refetch();

      handleCloseModal();
      reset({
        category: "",
        currency: "",
      });
    } catch (error: any) {
      console.error(error.message);
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      title="Add Currency"
      onClose={handleCloseModal}
      content={
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-2 px-8 py-6 pt-10 h-[200px] w-[501px]"
        >
          <div>
            <Controller
              name="category"
              control={control}
              render={({ field }) => (
                <BSelect
                  {...field}
                  options={options}
                  placeholder="Select Category"
                  className=" rounded-md"
                />
              )}
            />
            {errors.category && (
              <p className="text-red-500 text-sm">{errors.category.message}</p>
            )}
          </div>

          <div>
            <Controller
              name="currency"
              control={control}
              render={({ field }) => (
                <BSelect
                  {...field}
                  options={currencyOptions}
                  placeholder="Select Currency"
                  className="rounded-md"
                />
              )}
            />
            {errors.currency && (
              <p className="text-red-500 text-sm">{errors.currency.message}</p>
            )}
          </div>
        </form>
      }
      footer={
        <>
          <button
            type="button"
            className="bg-gray-200 text-gray-900 px-4 py-2 rounded-lg hover:bg-gray-300 w-[140px] h-[40px]"
            onClick={() => {
              reset({
                category: "",
                currency: "",
              });
              handleCloseModal();
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleSubmit(onSubmit)}
            className="bg-indigo-500 text-white px-4 py-2 rounded-lg hover:bg-indigo-600 w-[140px] h-[40px] ml-3"
          >
            Save
          </button>
        </>
      }
    />
  );
};

export default AddWalletForm;
